@import "colors";

$dotSize: 8px;
$size: $dotSize * 4;
$half: calc((3 * $dotSize) / 2);
$whole: $size - $dotSize;

@mixin top {
  top: 0;
  left: $half;
}
@mixin right {
  top: $half;
  left: $whole;
}
@mixin bottom {
  top: $whole;
  left: $half;
}
@mixin left {
  top: $half;
  left: 0;
}

.container {
  width: 100%;
  z-index: 7;
  overflow-y: hidden;
  animation: fadeIn 0.5s linear 1;
  background-color: $underlay-full-screen-loader-underlay;
  height: 100vh;
  top: 0;
  right: 0;
  position: fixed;
}

.loader {
  height: $size;
  width: $size;
  position: absolute;
  z-index: 4;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.dot {
  background-color: var(--primaryColor);
  height: $dotSize;
  width: $dotSize;
  border-radius: 50%;
  position: absolute;
}

.one {
  animation: loaderDot1 3s linear infinite;
}

.two {
  animation: loaderDot2 3s linear infinite;
}

.three {
  animation: loaderDot3 3s linear infinite;
}

.four {
  animation: loaderDot4 3s linear infinite;
}

@keyframes loaderDot1 {
  0% {
    @include top;
  }
  25% {
    @include right;
  }
  50% {
    @include bottom;
  }
  75% {
    @include left;
  }
  100% {
    @include top;
  }
}

@keyframes loaderDot2 {
  0% {
    @include right;
  }
  25% {
    @include bottom;
  }
  50% {
    @include left;
  }
  75% {
    @include top;
  }
  100% {
    @include right;
  }
}

@keyframes loaderDot3 {
  0% {
    @include bottom;
  }
  25% {
    @include left;
  }
  50% {
    @include top;
  }
  75% {
    @include right;
  }
  100% {
    @include bottom;
  }
}

@keyframes loaderDot4 {
  0% {
    @include left;
  }
  25% {
    @include top;
  }
  50% {
    @include right;
  }
  75% {
    @include bottom;
  }
  100% {
    @include left;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
