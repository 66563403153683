@import-normalize;

@font-face {
  font-family: "AvenirLTStd-Roman";
  font-weight: normal;
  src: url("webfonts/3818DC_0_0.eot");
  src: url("webfonts/3818DC_0_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/3818DC_0_0.woff2") format("woff2"), url("webfonts/3818DC_0_0.woff") format("woff"),
    url("webfonts/3818DC_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "AvenirLTStd-Roman";
  font-weight: bold;
  src: url("webfonts/3818DC_1_0.eot");
  src: url("webfonts/3818DC_1_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/3818DC_1_0.woff2") format("woff2"), url("webfonts/3818DC_1_0.woff") format("woff"),
    url("webfonts/3818DC_1_0.ttf") format("truetype");
}

body {
  font-family: "AvenirLTStd-Roman";
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-size: 1.4rem;
}
