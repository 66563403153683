.layout {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  background-color: var(--layoutBackgroundColor);
  color: var(--transactionalMainText);
  padding: 0 1.2rem;
}
