@import "colors";

@mixin backgroundColors($bg-color, $hover-color: null, $focus-color: $hover-color) {
  background-color: $bg-color;
  &:hover {
    @if $hover-color != null {
      background-color: $hover-color;
    } @else if type-of($bg-color) == color {
      background-color: darken($bg-color, 5%);
    } @else {
      @error "$bg-color is not a color value so I cannot perform my sass magic on it, is it a css variable? Then you have to provide the second argument for hover color as well.";
    }
  }
  &:focus {
    @if $focus-color != null {
      background-color: $focus-color;
    } @else if type-of($bg-color) == color {
      background-color: darken($bg-color, 5%);
    } @else {
      @error "$bg-color is not a color value so I cannot perform my sass magic on it, is it a css variable? Then you have to provide the second argument for hover color as well.";
    }
  }
}
.noHeight {
  height: 0;
}
.button {
  color: $white;
  outline: none;
  font: inherit;
  border: none;
  cursor: pointer;
  text-align: center;
  border-radius: 3rem;
  padding: 1rem 2.5rem 0.6rem;
  font-weight: bold;
  font-size: 1.6rem;
  border: 1px solid transparent;
  font-size: 2rem;
  margin-bottom: 1.5rem;
  min-height: 5rem;
  min-width: 15rem;
}

.primaryInverted {
  color: var(--primaryColor);
  @include backgroundColors(white, white);
  &.disabled {
    color: var(--primaryColor);
    @include backgroundColors(white, white);
    opacity: 0.2;
  }
}

.dark {
  @include backgroundColors($blue-dark-button);
  &.disabled {
    @include backgroundColors(rgba($blue-dark-button, 0.3), rgba($blue-dark-button, 0.3));
  }
}

.disabled {
  @include backgroundColors(var(--greyLightColor), var(--greyLightColor));
  color: $white;
  font-weight: bold;
  border: none;
  cursor: auto;
}

.shadow {
  box-shadow: 0 0.6rem 1.3rem -1rem $black-one;
}
