@import "colors";

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 34rem;
}

.input {
  margin-top: 1rem;
}

.button {
  margin-top: 2rem;
}

.success {
  color: $white;
  > span {
    display: block;
  }
}
.error {
  position: relative;
  top: 1rem;
}

.title {
  font-size: 2rem;
  margin-bottom: 0;
}

.description {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  white-space: pre-wrap;
  line-height: 2rem;
  text-align: left;
}
