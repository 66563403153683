@import "colors";

$paddingLeft: 2.4rem;

.wrapper {
  position: relative;
  display: inline-block;
}

.input {
  display: block;
  border-radius: 3rem;
  height: 5.5rem;
  width: 100%;
  color: var(--inputTextColor);
  padding: 0;
  font-size: 1.8rem;
  outline: none;
  flex-grow: 1;
  padding-left: $paddingLeft;
  border: 1px solid var(--inputBorderColor);
  background-color: var(--inputBackgroundColor);
  box-sizing: border-box;
  min-width: 290px;
  font-family: inherit;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px var(--inputBackgroundColor) inset;
    box-shadow: 0 0 0px 1000px var(--inputBackgroundColor) inset;
    -webkit-text-fill-color: var(--inputTextColor) !important;
  }

  &::placeholder {
    color: var(--inputPlaceholderColor);
    opacity: 1;
  }
  &.error {
    border: 1px solid $red-one;
    background-color: var(--inputErrorBackgroundColor);
    color: var(--inputErrorTextColor);
  }
  &.hasValue {
    padding-top: 1.6rem;
  }
}

.errorText,
.placeholderText {
  position: absolute;
  top: 1.2rem;
  font-size: 1.1rem;
}

.errorText {
  right: $paddingLeft;
  color: $red-one;
}

.placeholderText {
  left: $paddingLeft;
  color: var(--inputPlaceholderColor);
}

.placeholderTextError {
  color: var(--inputErrorTextColor);
}
